<template>
  <div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <!-- <th width="15%">
              <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
            </th> -->
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.advanceInvoice"
            >
              <vs-td class="whitespace-no-wrap">
                <div class="mt-4 flex mr-1">
                  <vx-tooltip text="View" class="mr-4">
                    <vs-button
                      color="warning"
                      type="line"
                      icon-pack="feather"
                      @click="edit(tr)"
                      icon="icon-eye"
                    />
                  </vx-tooltip>
                  <!-- <vx-tooltip text="Confirm" class="mr-4">
                    <vs-button
                      color="green"
                      type="line"
                      icon-pack="feather"
                      @click="promptConfirm(tr)"
                      icon="icon-check-circle"
                    />
                  </vx-tooltip> -->

                  <!-- <vx-tooltip text="Cancel" class="mr-4">
                    <vs-button
                      color="red"
                      type="line"
                      icon-pack="feather"
                      @click="promptCancel(tr)"
                      icon="icon-x-circle"
                    />
                  </vx-tooltip> -->
                </div>
              </vs-td>
              <vs-td> {{ tr.partner_code }} {{ tr.partner_name }} </vs-td>
              <vs-td>
                Code : {{ tr.code }}
                <br />
                External Code : {{ tr.external_code }}
                <br />
                Payment Term : {{ tr.payment_term_name }}
              </vs-td>
              <vs-td>
                <!-- {{ priceFormat(tr.external_code) }} -->
                <ul>
                  <li
                    v-for="(line, index) in tr.AdvanceInvoiceLine"
                    :key="index"
                  >
                    {{ line.document_code }}
                  </li>
                </ul>
              </vs-td>
              <vs-td>
                {{ dateFormat(tr.date) }}
              </vs-td>
              <vs-td>
                Total Invoice :
                {{
                  priceFormat(
                    calculateTotalAdvanceAmount(tr.AdvanceInvoiceLine)
                  )
                }}
                <br />
                Paid Value : {{ priceFormat(tr.TotalPayment) }}
                <br />
                Remaining : {{ priceFormat(calculateTotalAdvanceAmount(tr.AdvanceInvoiceLine) - tr.TotalPayment) }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="changeStatus"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="false"
        :active.sync="confirmPrompt"
      >
        <div class="con-exemple-prompt">
          Advance Invoice
          <br />
          Are you sure to confirm <b>{{ this.selectedData.code }}</b> Invoice ?
          <br />
          <br />
          <!-- <vs-textarea v-model="notes"/> -->
        </div>
      </vs-prompt>
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="changeStatus"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="false"
        :active.sync="cancelPrompt"
      >
        <div class="con-exemple-prompt">
          Advance Invoice
          <br />
          Are you sure to Cancel <b>{{ this.selectedData.code }}</b> invoice ?
          <br />
          <br />
          <!-- <vs-textarea v-model="notes"/> -->
        </div>
      </vs-prompt>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <template>
              <create
                :selectedData="selectedData"
                @closeDetail="closeDetail"
                :viewOnly="true"
              />
            </template>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import create from "../form.vue";
import edit from "./detail.vue";
import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
    territoryIDs: Array,
    selectedPO: Array,
    selectedExternal: Array,
  },
  components: {
    create,
    edit,
  },
  data() {
    return {
      typePayment: ["", "Giro", "Cheque", "Transfer"],
      confirmPrompt: false,
      cancelPrompt: false,
      status: 0,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "created_at",
      },
      header: [
        {
          text: "Action",
          sortable: false,
        },
        {
          text: "Supplier Data",
          value: "partner_code",
          // width: '5%'
        },
        {
          text: "Invoice",
          value: "reference_code",
        },
        {
          text: "Purchase Order Code",
          sortable: false,
        },
        {
          text: "Date",
          value: "date_payment",
        },
        {
          text: "Value",
          value: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
    };
  },
  computed: {},
  watch: {
    territoryIDs() {
      this.reloadData(this.params);
    },
    selectedPO() {
      this.reloadData(this.params);
    },
    selectedExternal() {
      this.reloadData(this.params);
    },
  },
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    calculateTotalAdvanceAmount(advanceInvoiceLines) {
      // Calculate the total advance amount for a given invoice
      return advanceInvoiceLines.reduce((total, line) => {
        return total + line.advance_amount;
      }, 0);
    },
    create() {
      this.selectedData = { ID: 0 };
      this.detail = true;
    },
    edit(selected) {
      this.selectedData = selected;
      this.detail = true;
    },
    promptConfirm(data) {
      this.confirmPrompt = true;
      this.status = 2;
      this.cancelPrompt = false;
      this.selectedData = data;
      console.log("confirm");
    },
    promptCancel(data) {
      this.confirmPrompt = false;
      this.status = 3;
      this.cancelPrompt = true;
      this.selectedData = data;
      console.log("confirm");
    },

    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    closeDetail(params = false) {
      console.log(params);
      window.scrollTo(0, 0);
      this.detail = false;
      this.confirmPrompt = false;
      this.status = 2;
      this.cancelPrompt = false;
      if (params == true || this.selectedData.ID != 0) {
        this.reloadData(this.params);
      }
      this.selectedData = {
        ID: 0,
      };
    },
    deleteInvoice() {
      let params = {
        id: this.selectedData.ID,
        status: this.status,
      };
      this.$vs.loading;
      this.$http
        .post("/api/v1/advance-invoice/delete", params)
        .then((result) => {
          this.$vs.loading.close();
          if (result.code == 200) {
            this.closeDetail();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.closeDetail();
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changeStatus() {
      let params = {
        id: this.selectedData.ID,
        status: this.status,
      };
      this.$vs.loading;
      this.$http
        .post("/api/v1/advance-invoice/change-status", params)
        .then((result) => {
          this.$vs.loading.close();
          if (result.code == 200) {
            this.closeDetail();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.closeDetail();
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/advance-invoice/data-table", {
            params: {
              status: 8,
              type: 1,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              territory_ids: this.territoryIDs,
              selected_po: this.selectedPO,
              selected_external: this.selectedExternal,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();
      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.advanceInvoice;
          console.log("r.data.otherPayment", r.data.advanceInvoice);
          this.responseData = r.data;
          this.responseData.length = r.data.advanceInvoice.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
