<template>
  <vx-card title="Advance Invoice">
    <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
      <div class="flex flex-col justify-between gap-2">
        <span>Territories</span>
        <multiselect
          v-model="selectedTerritories"
          :options="
            !territoryOptions || territoryOptions.length == 0
              ? []
              : territoryOptions
          "
          :multiple="true"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="3"
          placeholder="Type Territory"
          track-by="TerritoryID"
          label="TerritoryName"
          :custom-label="queryTerritory"
          @input="onTeritoryChange"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">
                ({{ props.option.TerritoryCode }}),
                {{ props.option.TerritoryName }}</span
              >
            </div>
          </template>
          <template slot="tag" slot-scope="props">
            <div class="multiselect__tag">
              <span>{{ props.option.TerritoryName }}</span>
              <i
                class="multiselect__tag-icon"
                @click.prevent
                @mousedown.prevent.stop="props.remove(props.option, $event)"
              />
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <br/>
    <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
      <div class="flex flex-col justify-between gap-2">
        <span>PO Code</span>
        <div class="input-container" @click="focusInputPO">
          <span
            v-for="(tag, index) in selectedTagsPO"
            :key="index"
            class="tag"
          >
            {{ tag }}
            <button @click.stop="removeTagPO(index)">×</button>
          </span>

          <!-- Input Field for New Tags -->
          <input
            ref="tagInputPOCode"
            v-model="tagInputPOCode"
            @keyup.enter="addTagPO"
            @keydown.delete="handleDeletePO"
            placeholder="Type and press Enter"
          />
        </div>
      </div>
    </div>
    <br/>
    <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
      <div class="flex flex-col justify-between gap-2">
        <span>External Code</span>
        <div class="input-container" @click="focusInputExternalCode">
          <span
            v-for="(tag, index) in selectedTagsExternalCode"
            :key="index"
            class="tag"
          >
            {{ tag }}
            <button @click.stop="removeTagExternalCode(index)">×</button>
          </span>

          <!-- Input Field for New Tags -->
          <input
            ref="tagInputExternalCode"
            v-model="tagInputExternalCode"
            @keyup.enter="addTagExternalCode"
            @keydown.delete="handleDeleteExternalCode"
            placeholder="Type and press Enter"
          />
        </div>
      </div>
    </div>
    <br/>
    <vs-button class="mb-2" @click="handleOpenForm">Create</vs-button>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-tabs v-model="tab">
          <vs-tab label="Draft" >
            <open :key="componentKey" :territoryIDs="territoryIDs" :selectedPO="selectedTagsPO" :selectedExternal="selectedTagsExternalCode"></open>
          </vs-tab>
          <vs-tab label="Pending" :focus="true">
            <div class="tab-text">
              <pending :territoryIDs="territoryIDs" :selectedPO="selectedTagsPO" :selectedExternal="selectedTagsExternalCode"></pending>
            </div>
          </vs-tab>
          <vs-tab label="Approved">
            <div class="tab-text">
              <Approved :territoryIDs="territoryIDs" :selectedPO="selectedTagsPO" :selectedExternal="selectedTagsExternalCode"></Approved>
            </div>
          </vs-tab>
          <vs-tab label="Paid">
            <div class="tab-text">
              <Paid :territoryIDs="territoryIDs" :selectedPO="selectedTagsPO" :selectedExternal="selectedTagsExternalCode"></Paid>
            </div>
          </vs-tab>
          <vs-tab label="Cleared">
            <div class="tab-text">
              <Cleared :territoryIDs="territoryIDs" :selectedPO="selectedTagsPO" :selectedExternal="selectedTagsExternalCode"></Cleared>
            </div>
          </vs-tab>
          <vs-tab label="Rejected">
            <div class="tab-text">
              <Reject :territoryIDs="territoryIDs" :selectedPO="selectedTagsPO" :selectedExternal="selectedTagsExternalCode"></Reject>
            </div>
          </vs-tab>
          <vs-tab label="Canceled">
            <div class="tab-text">
              <Cancel :territoryIDs="territoryIDs" :selectedPO="selectedTagsPO" :selectedExternal="selectedTagsExternalCode"></Cancel>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <form-create @closeDetail="handleClose" :selectedData="{}"> </form-create>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import Open from "./open";
import Pending from "./pending";
import Cancel from "./cancel";
import Cleared from "./cleared";
import Paid from "./paid";
import Reject from "./reject";
import Approved from "./approved";
import FormCreate from "./form.vue";
import territoryFilter from "../../../../components/operatingUnitFilter/territoryFilter.vue";
export default {
  components: {
    Open,
    Pending,
    Approved,
    Reject,
    Paid,
    Cleared,
    Cancel,
    FormCreate,
    "territory-filter": territoryFilter,
  },
  data() {
    const code =this.$route.params.code
    const status =this.$route.params.status
    const tabStsPosition=[
        1,//0(Pending)=>tab1
        3,//1(approved)=>tab3
        2,//2(rejected)=>tab2
        4,//3(applied)=>tab4
        6,//4(reverse)=>tab6
        0,//5(waiting reverse)=>?
        0 //6(waiting reverse to open)=>?
      ]
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      tab:code&&status&&parseInt(status)<7&&parseInt(status)>=0?tabStsPosition[parseInt(status)]:0,
      dn_id: "",
      debitNoteCode: code,
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      territoryOptions: [],
      territoryIDs: [],
      tagInputPOCode: "",
      tagInputExternalCode: "",
      selectedTagsPO: [],
      selectedTagsExternalCode: [],
      items: ["Vue.js", "React", "Angular", "Node.js", "JavaScript"],
      componentKey: 0,
      territoryOptionAll: {
        ID: 0,
        TerritoryCode: "All",
        TerritoryName: "All",
      },
    };
  },
  mounted () {
    this.territoryOptions = this.$store.state.operatingUnit.filter.territory;
    this.territoryIDs =
    this.$store.getters["operatingUnit/getSelectedTerritoryId"];
    this.selectedTerritories = [this.territoryOptions[0]];
  },
  computed: {},
  methods: {
    handleClose() {
      console.log("close form");
      this.detail = false;
      this.componentKey += 1;
    },
    handleOpenForm() {
      console.log("open form");
      this.detail = true;
      this.status_detail = "form-create";
    },
    handleResetDnCode(){
      this.debitNoteCode =""
    },
    onTeritoryChange(value) {
      if (value.length === 0) {
        this.selectedTerritories = [this.territoryOptionAll];
        this.territoryIDs =
          this.$store.getters["operatingUnit/getSelectedTerritoryId"];
        return;
      }

      if (value[0].ID === 0) {
        value.shift();
        this.selectedTerritories = value;
      } else if (value.some((ou) => ou.ID === 0)) {
        this.selectedTerritories = [this.territoryOptionAll];
        this.territoryIDs =
          this.$store.getters["operatingUnit/getSelectedTerritoryId"];
        return;
      } else {
        this.selectedTerritories = value;
      }
      this.territoryIDs = this.selectedTerritories.map((ou) => ou.TerritoryID);
    },
    queryTerritory({ TerritoryCode, TerritoryName }) {
      return `(${TerritoryCode}), ${TerritoryName}`;
    },
    addTagPO() {
      // Split input by semicolon and trim each value
      let newTags = this.tagInputPOCode
        .split(";")
        .map(tag => tag.trim())
        .filter(tag => tag !== ""); // Remove empty values

      // Add only unique values
      newTags.forEach(tag => {
        if (!this.selectedTagsPO.includes(tag)) {
          this.selectedTagsPO.push(tag);
        }
      });

      // Clear input field
      this.tagInputPOCode = "";
    },
    removeTagPO(index) {
      this.selectedTagsPO.splice(index, 1);
    },
    focusInputPO() {
      this.$refs.tagInputPOCode.focus();
    },
    handleDeletePO(event) {
      if (event.key === "Backspace" && this.tagInputPOCode === "" && this.selectedTagsPO.length > 0) {
        this.selectedTagsPO.pop();
      }
    },
    addTagExternalCode() {
      // Split input by semicolon and trim each value
      let newTags = this.tagInputExternalCode
        .split(";")
        .map(tag => tag.trim())
        .filter(tag => tag !== ""); // Remove empty values

      // Add only unique values
      newTags.forEach(tag => {
        if (!this.selectedTagsExternalCode.includes(tag)) {
          this.selectedTagsExternalCode.push(tag);
        }
      });

      // Clear input field
      this.tagInputExternalCode = "";
    },
    removeTagExternalCode(index) {
      this.selectedTagsExternalCode.splice(index, 1);
    },
    focusInputExternalCode() {
      this.$refs.tagInputExternalCode.focus();
    },
    handleDeleteExternalCode(event) {
      if (event.key === "Backspace" && this.tagInputExternalCode === "" && this.selectedTagsExternalCode.length > 0) {
        this.selectedTagsExternalCode.pop();
      }
    },
  },
};
</script>
<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}

.input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  min-height: 40px;
  cursor: text;
}
.tag {
  display: flex;
  align-items: center;
  background: #41b883;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  margin-right: 5px;
}
.tag button {
  background: none;
  border: none;
  color: white;
  margin-left: 5px;
  cursor: pointer;
  font-size: 16px;
}
input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 5px;
  min-width: 50px;
}
</style>